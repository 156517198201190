<template>
  <relato
    class="mt-3"
    :idEmpresa="id"
  />
</template>

<script>
import relato from '../my-components/ralatos/form/fazerRelato.vue';

export default {
  components: { relato },
  data() {
    return {
      nomeDaEmpresa: null,
      id: null,
    };
  },
  created() {
    this.nomeDaEmpresa = this.$router.history.current.params.empresa;
    this.escolherEmpresa(this.nomeDaEmpresa);
  },
  methods: {
    escolherEmpresa(nome) {
      switch (nome) {
      case 'IndustriasDureino':
        this.id = 3;
        break;
      case 'EldoradoBebidas':
        this.id = 9;
        break;
      case 'GestorConsultoria':
        this.id = 2;
        break;
      case 'Mambee':
        this.id = 4;
        break;
      case 'ViaBebidas':
        this.id = 11;
        break;
      case 'Alfa':
        this.id = 12;
        break;
      default:
        this.modalErro();
        break;
      }
    },
    modalErro() {
      this.$swal({
        type: 'error',
        title: 'Oops...',
        text: 'Link Incorreto',
        backdrop: 'rgba(0,0,0,1)',

        allowOutsideClick: false,
        preConfirm: () => {
          this.$router.push('/');
        },
      });
    },
  },
};
</script>
